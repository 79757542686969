.Timer {
  display: flex;
  align-items: center;

  &__watchIcon {
    &.MuiSvgIcon-root {
      width: 13px;
    }
  }

  &__spentTime {
    font-size: 12px;

    &.bigSize {
      font-size: 24px;
    }
  }

  &__button {
    cursor: pointer;

    &.MuiSvgIcon-root {
      width: 14px;
      height: 12px;
    }
  }
}
