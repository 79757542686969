.LoginPage {
  width: 400px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 460px) {
    width: 300px;
  }

  &__login-form {
    flex-grow: 1;
  }

  &__greetings {
    font-size: 12px;
    margin-bottom: 12px;
    text-align: center;

    a {
      font-size: 12px;
    }
  }
}
