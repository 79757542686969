@import './colors';
@import './animations';

$leafWidth: 240px;
$themeDarkColorText: rgb(222, 200, 200);

@mixin aimTypography {
  color: $color_text_2;
  line-height: 1.2em;
}

* {
  box-sizing: border-box;
}
