@import '~styles';

.EitherVimiumExtensionWarning {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    max-width: 300px;
  }

  &__button {
    cursor: pointer;

    &:active {
      opacity: 0.9;
    }

    &.copied {
      color: green;
    }
  }

  &__code {
    font-weight: bold;
    margin: 8px 0px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
  }

  &__body {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin-left: 12px;
  }
}
