@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.005, 1.005, 1.005);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes shadow-pulse {
  from {
    box-shadow: 0px 0px 1px green;
  }

  50% {
    box-shadow: 0px 0px 3px green;
  }

  to {
    box-shadow: 0px 0px 1px green;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
