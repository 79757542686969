@import '~styles';

.SignUpForm {
  &__field {
    & + & {
      margin-top: 20px;
    }
  }

  &__error-msg {
    $error-margin-top: 18px;

    color: #f44336;
    font-family: 'Roboto';
    font-size: 12px;
    margin-top: $error-margin-top;

    animation: fade-in 200ms;

    &-placeholder {
      margin-top: $error-margin-top;
      height: 18px;
    }
  }

  $submit-button-margin: 4px;

  &__submit-btn {
    margin-top: $submit-button-margin;
  }

  &__link {
    margin-top: $submit-button-margin;
    font-size: 12px;
    font-family: 'Roboto';
    color: #3f51b5;
  }
}
