@import '~styles';

.ColorInput {
  position: relative;

  &__input {
    display: flex;
    align-items: center;
  }

  &__color {
    border: 1px solid #000;
    border-radius: 6px;
    width: 80px;
    height: 40px;
    margin-right: 12px;
  }

  &__colorPicker {
    position: fixed;
    bottom: 24px;
    left: 24px;
  }

  &__overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
