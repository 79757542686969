.bezier-line {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  overflow: visible;

  &__particle {
    stroke-width: 1.5px;
    fill: none;
    stroke: #000;
  }

  path,
  polyline {
    fill: none;
  }
}
