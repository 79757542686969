@import '~styles';

.ConfirmDenyDialog {
  min-width: 320px;

  &__children {
    margin-top: 12px;
    padding: 0 12px;
  }

  &__error {
    margin-top: 6px;
    padding: 0 12px;
    color: red;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 12px;
    margin-bottom: 8px;
    margin-right: 8px;

    button + button {
      margin-left: 8px;
    }
  }
}
