.SignUpPage {
  width: 400px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: 460px) {
    width: 300px;
  }

  &__form {
    flex-grow: 1;
  }
}

.SignUpPagePlaceholder {
  width: 800px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    font-size: 32px;
    text-align: center;
  }
}
